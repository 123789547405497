// Importeer de Typewriter-bibliotheek voor het typemachine-effect
import Typewriter from "typewriter-effect/dist/core";

// CODE HEB IK VOOR HET GROOTSTE GEDEELTE ZELF GESCHREVEN EN/OF AANGEPAST, MAAR LATEN VERBETEREN EN DOCUMENTEREN DOOR AI

// Wacht tot het document volledig geladen is
$(document).ready(function () {
  // Zet de scrollpositie van de pagina op 0
  $(window).scrollTop(0);

  // Initialiseer het Typewriter-effect voor de banner
  const typewriter = new Typewriter(".banner-text", {
    loop: true,
    delay: 35,
    deleteSpeed: 40,
    autoStart: true,
    cursor: "",
  });

  // Voeg tekst toe aan het Typewriter-effect
  typewriter
    .pauseFor(500)
    .typeString("Een full-stack developer in opleiding!")
    .pauseFor(1000)
    .deleteAll()
    .pauseFor(250)
    .typeString("Leergierig en altijd op zoek naar nieuwe uitdagingen!")
    .pauseFor(1000)
    .deleteAll()
    .pauseFor(250)
    .typeString(
      "<strong>Een <span style='text-decoration:underline;'>échte</span> teamplayer!</strong>"
    )
    .pauseFor(1000)
    .start();

  // Sterrenanimatie op de achtergrond
  const canvas = document.getElementById("space");
  const context = canvas.getContext("2d");
  const screenH = window.innerHeight;
  const screenW = window.innerWidth;
  const stars = [];
  const numStars = 500;

  // Initialiseer canvas afmetingen
  canvas.height = screenH;
  canvas.width = screenW;

  // Definieer het Star object voor de sterren
  function Star(x, y, length, opacity) {
    this.x = parseInt(x);
    this.y = parseInt(y);
    this.length = parseInt(length);
    this.opacity = opacity;
    this.factor = 1;
    this.increment = Math.random() * 0.03;
  }

  // Voeg een methode toe aan het Star object om te tekenen
  Star.prototype.draw = function () {
    context.rotate((Math.PI * 1) / 10);
    context.save();
    context.translate(this.x, this.y);

    if (this.opacity > 1) {
      this.factor = -1;
    } else if (this.opacity <= 0) {
      this.factor = 1;
      this.x = Math.round(Math.random() * screenW);
      this.y = Math.round(Math.random() * screenH);
    }

    this.opacity += this.increment * this.factor;

    context.beginPath();
    for (let i = 5; i--; ) {
      context.lineTo(0, this.length);
      context.translate(0, this.length);
      context.rotate((Math.PI * 2) / 10);
      context.lineTo(0, -this.length);
      context.translate(0, -this.length);
      context.rotate(-((Math.PI * 6) / 10));
    }
    context.lineTo(0, this.length);
    context.closePath();
    context.fillStyle = `rgba(255, 255, 200, ${this.opacity})`;
    context.shadowBlur = 5;
    context.shadowColor = "#fff";
    context.fill();

    context.restore();
  };

  // Genereer willekeurige sterren en voeg ze toe aan de stars array
  for (let i = 0; i < numStars; i++) {
    const x = Math.round(Math.random() * screenW);
    const y = Math.round(Math.random() * screenH);
    const length = 1 + Math.random() * 1.5;
    const opacity = Math.random();

    let star = new Star(x, y, length, opacity);
    stars.push(star);
  }

  // Animatie functie voor het tekenen van de sterren
  function animate() {
    context.clearRect(0, 0, screenW, screenH);
    stars.forEach((star) => star.draw());

    requestAnimationFrame(animate);
  }

  // Start de animatie
  animate();

  // Voeg een scroll eventlistener toe aan het document
  $(document).on("scroll", function () {
    const scroll_pos = $(this).scrollTop();
    const sectionScrollPositions = [100, 500, 1100, 2200, 2900];
    const sectionClasses = [
      ["navigation", "square", "about"],
      ["skills"],
      ["projects"],
      ["interests"],
      ["contact"],
    ];

    // Activeer secties op basis van de scrollpositie
    sectionScrollPositions.forEach((pos, index) => {
      sectionClasses[index].forEach((className) => {
        const element = $(`.${className}`);
        if (scroll_pos >= pos) {
          element.addClass("active");
        } else {
          element.removeClass("active");
        }
      });
    });
  });

  // Elementen voor de project slider animatie
  const $activeSlide = $(".active");
  const $homeSlide = $(".slide");
  const $slideNavPrev = $("#prev");
  const $slideNavNext = $("#next");

  // Initialiseer de animatie
  function init() {
    TweenMax.set($homeSlide.not($activeSlide), { autoAlpha: 0 });
    TweenMax.set([$slideNavPrev, $slideNavNext], { autoAlpha: 0.2 });
  }

  // Start de animatie
  init();

  // Functie om naar de volgende of vorige dia te gaan
  function goToSlide(slideOut, slideIn, slideInAll, direction) {
    const tl = new TimelineLite();
    const slideOutContent = slideOut.find(".card__content");
    const slideInContent = slideIn.find(".card__content");
    const slideOutImg = slideOut.find(".card__img");
    const slideInImg = slideIn.find(".card__img");
    const index = slideIn.index();
    const size = $homeSlide.length;

    if (slideIn.length !== 0) {
      tl.set(slideIn, { autoAlpha: 1, className: "slide active" })
        .set(slideOut, { className: "slide" })
        .to(
          slideOutContent,
          0.65,
          { y: `${direction}=40px`, ease: Power3.easeInOut },
          0
        )
        .to(
          slideOutImg,
          0.65,
          {
            backgroundPosition: direction === "next" ? "bottom" : "top",
            ease: Power3.easeInOut,
          },
          0
        )
        .to(
          slideInAll,
          1,
          {
            y: direction === "next" ? "-=100%" : "+=100%",
            ease: Power3.easeInOut,
          },
          0
        )
        .fromTo(
          slideInContent,
          0.65,
          { y: `${direction === "next" ? "-" : "+"}=40px` },
          { y: 0, ease: Power3.easeInOut },
          "-=0.7"
        )
        .fromTo(
          slideInImg,
          0.65,
          { backgroundPosition: direction === "next" ? "top" : "bottom" },
          {
            backgroundPosition: direction === "next" ? "bottom" : "top",
            ease: Power3.easeInOut,
          },
          "-=0.7"
        );
    }

    // Toon of verberg navigatieknoppen op basis van de positie van de dia
    TweenMax.set($slideNavPrev, { autoAlpha: 1 });
    TweenMax.set($slideNavNext, { autoAlpha: 1 });

    if (index === size - 1) {
      TweenMax.to($slideNavNext, 0.3, {
        autoAlpha: 0.2,
        ease: Linear.easeNone,
      });
    } else if (index === 0) {
      TweenMax.to($slideNavPrev, 0.3, {
        autoAlpha: 0.2,
        ease: Linear.easeNone,
      });
    }
  }

  // Eventlistener voor de volgende dia
  $slideNavNext.on("click", function (e) {
    e.preventDefault();
    const slideOut = $(".slide.active");
    const slideIn = $(".slide.active").next(".slide");
    const slideInAll = $(".slide");
    goToSlide(slideOut, slideIn, slideInAll, "next");
  });

  // Eventlistener voor de vorige dia
  $slideNavPrev.on("click", function (e) {
    e.preventDefault();
    const slideOut = $(".slide.active");
    const slideIn = $(".slide.active").prev(".slide");
    const slideInAll = $(".slide");
    goToSlide(slideOut, slideIn, slideInAll, "prev");
  });

  // Eventlisteners voor het openen en sluiten van de project sliders
  $(".card__content-link").on("click", function (e) {
    e.preventDefault();
    const cardSlider = $(this).closest(".cardSlider");
    cardSlider.addClass("active");
  });

  $(".card__content-linkBack").on("click", function (e) {
    e.preventDefault();
    const cardSlider = $(this).closest(".cardSlider");
    cardSlider.removeClass("active");
  });
});
